<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              <feather-icon
                icon="ArrowLeftIcon"
                size="24"
                class="mr-2"
                @click="goBack"
              />EggStock
            </h2>
            <div class="breadcrumb-wrapper">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <span aria-current="location"> Add </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <validation-observer ref="simpleRules">
      <b-form method="post" @submit="addEggStock">
        <b-card>
          <b-row>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Purchase-Date<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="purchase date"
                  rules="required"
                >
                  <b-form-datepicker
                    name="purchase date"
                    v-model="FormData.purchase_date"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Flock id<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Flock id"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.flock_id"
                      placeholder="Flock id"
                      type="text"
                      name="flock_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Source<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Source"
                    rules="required"
                  >
                    <v-select
                      v-model="FormData.source"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="sourceList"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.slug"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Breed<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Breed"
                    rules="required"
                  >
                    <v-select
                      v-model="FormData.breed"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="breedList"
                      label="name"
                      clearable:false
                      :reduce="(e) => e.name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Age(in week)<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Age(in week)"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.flock_age"
                      placeholder="Flock Age"
                      type="number"
                      name="flock_age"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Egg laid date<span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Egg laid date"
                  rules="required"
                >
                  <b-form-datepicker
                    v-model="FormData.egg_laid_date"
                    name="egg_laid_date"
                    menu-class="w-100"
                    calendar-width="100%"
                    class="mb-1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Batch id<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Batch id"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.batch_id"
                      placeholder="Batch id"
                      type="text"
                      name="batch_id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Egg Qty<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name=" Egg Qty"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.egg_qty"
                      placeholder="Egg Qty"
                      type="number"
                      name="egg_qty"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Price Per Egg<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Price Per Egg"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.price_per_egg"
                      placeholder="Price Per Egg"
                      type="number"
                      name="price_per_egg"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group>
                <template v-slot:label>
                  Damaged<span class="text-danger">*</span>
                </template>
                <div class="form-label-group">
                  <validation-provider
                    #default="{ errors }"
                    name="Damaged"
                    rules="required"
                  >
                    <b-form-input
                      v-model="FormData.damaged"
                      placeholder="Damaged"
                      type="text"
                      name="damaged"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </b-form-group>
            </b-col>

            <!-- submit and reset -->
            <b-col>
              <b-button
                type="submit"
                variant="primary"
                value="Submit"
                class="mr-1"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import * as Vue from "vue";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BFormSelect,
  BFormDatepicker,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { required, email } from '@validations'
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    BFormSelect,
    BFormDatepicker,
    flatPickr,
    vSelect,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      required,
      breedList: [],
      sourceList: [],
      FormData: {
        purchase_date: null,
        flock_id: null,
        batch_id: null,
        egg_qty: null,
        flock_age: null,
        price_per_egg: null,
        damaged: null,
        egg_laid_date: null,
        source: null,
        breed: null,
      },
    };
  },
  created: function () {
    this.getSource();
    this.getBreeds();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getSource() {
      axiosIns
        .get(`web/get-all-source`)
        .then((response) => {
          this.sourceList = response.data.source_list;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    getBreeds() {
      axiosIns
        .get(`web/get-all-breed`)
        .then((response) => {
          this.breedList = response.data.breedList;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
    addEggStock(e) {
      e.preventDefault();
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const hatchery_id = this.$route.params.hatcheryId;
          var data = new FormData();
          data.append("flock_id", this.FormData.flock_id);
          data.append("breed", this.FormData.breed);
          data.append("batch_id", this.FormData.batch_id);
          data.append("egg_qty", this.FormData.egg_qty);
          data.append("flock_age", this.FormData.flock_age);
          data.append("price_per_egg", this.FormData.price_per_egg);
          data.append("damaged", this.FormData.damaged);
          data.append("purchase_date", this.FormData.purchase_date);
          data.append("egg_laid_date", this.FormData.egg_laid_date);
          data.append("source", this.FormData.source);

          data.append("hatchery_id", this.$route.params.hatcheryId);
          axiosIns
            .post(`web/hatchery/${hatchery_id}/purchaseEgg`, data)
            .then((res) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `Data Successfully Added `,
                },
              });
              this.$router.push({
                name: `apps-purchase`,
              });
            })
            .catch((error) => {
              error.response;
              const data = error.response.data.errors;
              data, "data";
              let arr = Object.values(data).flat().toString(",");

              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: arr,
                },
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
